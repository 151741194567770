export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      
      <div className="about--section--img">
        <img src="../img/about-me.png" alt="About Me" />
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
        
          <h1 className="skills-section--heading">About Me</h1>
          <p className="hero--section-description">
          My name is Mohammed Mourtaja. I am a Palestinian from Gaza majoring in economics and minoring in data science and Middle Eastern & South Asian studies at Washington and Lee University. Growing up in Gaza, I experienced firsthand the profound challenges posed by the Israeli occupation, particularly its impact on our education system. These hardships motivated me to pursue opportunities abroad, leading to my acceptance at Washington and Lee University on a full scholarship. While at the university, I served as a research scholar for Professor Art Goldsmith, contributing to his research on labor economics, and for Professor Seth Cantey, exploring Bitcoin’s impact on international relations. Beyond academics, I have interned at the Jerusalem Fund, focusing on advocacy and education. Currently, I am a part-time consultant for the Israel/Palestine conflict resolution team at the Carter Center, while finishing my senior year at college. My work has been featured in Middle East Eye, The New Arab, Mondoweiss, and BTC magazine.

          </p>
        
        </div>
      </div>
    </section>
  );
}
